import React from 'react';
import './PageContainer.css';

const PageContainer = ({ children }) => {
  return (
    <div className="PageContainer">
      {children}
    </div>
  );
};

export default PageContainer;
