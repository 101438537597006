import React from 'react';
import { Link } from 'react-router-dom';
import './PageHeader.css';

const PageHeader = ({ title, children, backHref }) => {
  return (
    <div className="PageHeader">
      {backHref && (
        <nav>
          <Link to={backHref}>Back</Link>
        </nav>
      )}
      {title && <h1>{title}</h1>}
      {children}
    </div>
  );
}

export default PageHeader;
