import React from 'react';
import classnames from 'classnames';
import './Icon.css';

const Icon = ({ name, large }) => (
  <svg viewBox="0 0 32 32" className={classnames('Icon', { large })}>
    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#${name}`} />
  </svg>
);

export default Icon;
