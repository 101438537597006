import chroma from 'chroma-js';

export const randomColorHSV = (h, s, b, ch, cs, cb) => {
  return chroma.hsv(
    h + (Math.random() * ch) - (ch/2),
    s + (Math.random() * cs) - (cs/2),
    b + (Math.random() * cb) - (cb/2),
  );
}

export const randomColorNear = (center, maxRatio = 0.2) => {
  const c = chroma(center);

  const r = chroma.random();

  return chroma.mix(c, r, Math.random() * maxRatio);
};

export const randomPosition = (center, extent) => {
  const [x, y, z] = center;
  const [sx, sy, sz] = extent;

  return [
    x + (Math.random() * sx) - (sx / 2),
    y + (Math.random() * sy) - (sy / 2),
    z + (Math.random() * sz) - (sz / 2),
  ].join(' ');
};

export const randomPositionDisc = (center, rmin, rmax, height) => {
  const [x, y, z] = center;

  const angle = Math.random() * 2 * Math.PI;
  const r = rmin + Math.random() * (rmax - rmin);

  return [
    x + r * Math.sin(angle),
    y + (Math.random() * height) - (height/2),
    z + r * Math.cos(angle),
  ].join(' ');
}
