import React, { createContext, useContext, useReducer, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Enums, EchoClient, ConfigKeys } from '@bbc/echo-client-js';
import { v4 as uuidv4 } from 'uuid';

import {
  enableAnalytics,
  showTasterBadge,
  presets,
  defaultSettings,
  counterName,
  pilotId,
} from './config.js';

import HomePage from './Home/index.js';
import ListenPage from './Listen/index.js';
import ImportPage from './Import/index.js';
import InspectPage from './Inspect/index.js';
import LinkPage from './Link/index.js';
import IconDefinitions from './components/IconDefinitions.js';
import TasterBadge from './components/TasterBadge.js';
import { fetchMixMetadata } from './mixString';
import PageViewLogger from './PageViewLogger';
import './App.css';

const StateContext = createContext({});
const DispatchContext = createContext(() => {});

const emptySoundMetadata = {}

export const useMixObject = () => useContext(StateContext).mixObject;
export const useSoundMetadata = () => useContext(StateContext).metadata || emptySoundMetadata;
export const useSettings = () => useContext(StateContext).settings;
export const useDispatch = () => useContext(DispatchContext);

let echo;

const analyticsId = uuidv4();

const devMode = process.env.NODE_ENV === 'development';

const enableInspector = devMode;

if (enableAnalytics) {
  const conf = {
    [ConfigKeys.DESTINATION]: devMode ? Enums.Destinations.TASTER_TEST : Enums.Destinations.TASTER,
    [ConfigKeys.DEBUG_MODE]: devMode ? 'info' : false,
  };
  echo = new EchoClient(
    'soundscape-explorer',
    Enums.ApplicationType.WEB,
    conf,
    null,
    // callback for when client has successfully loaded - send initial pageview.
    () => {},
  );
  echo.setCounterName(counterName);
}

const logViewEvent = (page, presetId, section) => {
  if (!echo) {
    return;
  }
  echo.viewEvent(counterName, {
    section: `${page}::${presetId}::${section}`,
  });
};

const logCustomEvent = ({
  actionType,
  actionName,
  container = 'default',
  background = true,
  metadata = {},
}) => {
  if (!echo) {
    return;
  }
  echo.userActionEvent(actionType, actionName, {
    is_background: background,
    container,
    metadata: Object.entries({
      s: analyticsId,
      ...metadata,
    }).map(([k, v]) => `${k}=${v}`).join('~'),
  });
};

const initialState = {
  settings: defaultSettings,
};

const reducer = (state, action) => {
  switch (action.type) {
  case 'CHANGE_SETTINGS':
    return {
      ...state,
      settings: {
        ...state.settings,
        ...action.settings,
      },
    };
  case 'ADD_METADATA':
    return {
      ...state,
      metadata: {
        ...(state.metadata || {}),
        ...action.metadata,
      },
    };
  case 'SET_MIX_OBJECT':
    return {
      ...state,
      mixObject: action.mixObject,
    };
  case 'LOG_PAGEVIEW':
    logViewEvent(action.page, action.presetId, action.section);
    return state;
  case 'LOG_CUSTOM':
    logCustomEvent(action.custom);
    return state;
  default:
    return state;
  }
};

function App({
  config,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.mixObject) {
      if (state.settings.enableMetadataFetching) {
        fetchMixMetadata(state.mixObject).then((metadata) => {
          dispatch({
            type: 'ADD_METADATA',
            metadata,
          });
        });
      }
    }
  }, [state.mixObject, state.settings.enableMetadataFetching]);

  return (
    <>
      <IconDefinitions />
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          <Router>
            <PageViewLogger logViewEvent={logViewEvent} />
            <div className="App">
              <main>
                <Switch>
                  <Route exact path="/">
                    <HomePage presets={presets} />
                  </Route>
                  <Route path="/listen/:id">
                    <ListenPage presets={presets} />
                  </Route>
                  <Route exact path="/link">
                    <LinkPage />
                  </Route>
                  <Route exact path="/import">
                    <ImportPage />
                  </Route>
                  {enableInspector && (
                    <Route exact path="/inspect">
                      <InspectPage />
                    </Route>
                  )}
                  <Redirect to="/" />
                </Switch>
              </main>
              <footer>
                {showTasterBadge && <TasterBadge pilotId={pilotId} />}
              </footer>
            </div>
          </Router>
        </DispatchContext.Provider>
      </StateContext.Provider>
    </>
  );
}

export default App;
