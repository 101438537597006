import { encodeMixString, snowParticleConfiguration } from './common';

const christmas = {
  id: 'visit-from-st-nicholas',
  title: 'A Visit from St. Nicholas',
  description: 'Clement Clarke Moore\'s Christmas poem (1823). Read by Nick Bright, with sound design by Jon Francombe.',
  imageUrl: '/assets/christmas-preview.jpg',
  imageAlt: 'Winter park scene with trees and a light coating of snow',
  mixString: encodeMixString({
    "simplifiedMixerAssets": {
      "1": {
        "id": "07012095",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.33,
          "start": 0.1,
          "end": 40,
          "delay": 47,
          "position": {
            "x": -2.54,
            "y": 1.68,
            "z": -1.73
          }
        }
      },
      "2": {
        "id": "07027086",
        "mixerSettings": {
          "isLooping": false,
          "volume": 1,
          "start": 0,
          "end": 7.53,
          "delay": 10,
          "position": {
            "x": 2.16,
            "y": 1.6,
            "z": -0.14
          }
        }
      },
      "3": {
        "id": "07061005",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.33,
          "start": 0.28,
          "end": 14.00,
          "delay": null,
          "position": {
            "x": 3.53,
            "y": 1.83,
            "z": 2.77
          }
        }
      },
      "4": {
        "id": "NHU05008080",
        "mixerSettings": {
          "isLooping": true,
          "volume": 0.33,
          "start": null,
          "end": null,
          "delay": 12,
          "position": {
            "x": -0.05,
            "y": 1.8,
            "z": 3.54
          }
        }
      },
      "5": {
        "id": "07004054",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.33,
          "position": {
            "x": -3.1,
            "y": 1.13,
            "z": 0.3
          },
          "start": 0.1,
          "end": 19,
          "delay": 84
        }
      },
      "8": {
        "id": "07039356",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.5,
          "start": 0.1,
          "end": 27,
          "delay": 60,
          "position": {
            "x": -1.03,
            "y": 1.65,
            "z": -3.56
          }
        }
      },
      "11": {
        "id": "07061005",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.16,
          "start": 13.75,
          "end": 45.06,
          "delay": 14,
          "position": {
            "x": 1.95,
            "y": 1.85,
            "z": 5.07
          }
        }
      },
      "13": {
        "id": "NHU10217728",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.17,
          "start": null,
          "end": null,
          "delay": 109,
          "position": {
            "x": 1.8,
            "y": 1.65,
            "z": 3.05
          }
        }
      },
      "16": {
        "id": "07024148",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.67,
          "start": null,
          "end": null,
          "delay": 123,
          "position": {
            "x": 0.52,
            "y": 4.49,
            "z": -3.08
          }
        }
      },
      "20": {
        "id": "07036123",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.33,
          "start": 0,
          "end": 14.71,
          "delay": 124,
          "position": {
            "x": 1.29,
            "y": 4.15,
            "z": -2.52
          }
        }
      },
      "22": {
        "id": "07027188",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.83,
          "start": null,
          "end": null,
          "delay": 170,
          "position": {
            "x": 1.95,
            "y": 1.42,
            "z": -2.76
          }
        }
      },
      "23": {
        "id": "07027182",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.67,
          "start": null,
          "end": null,
          "delay": 89,
          "position": {
            "x": -3.82,
            "y": 1.65,
            "z": -1.15
          }
        }
      },
      "27": {
        "id": "07037520",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.67,
          "start": 5,
          "end": 10,
          "delay": 59,
          "position": {
            "x": -2.23,
            "y": 1.89,
            "z": -2.71
          }
        }
      },
      "30": {
        "id": "NHU05104161",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.33,
          "start": 0.1,
          "end": 18,
          "delay": 86,
          "position": {
            "x": -3.41,
            "y": 1.61,
            "z": -0.44
          }
        }
      },
      "31": {
        "id": "07037432",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.83,
          "start": null,
          "end": null,
          "delay": 132,
          "position": {
            "x": 0.03,
            "y": 1.27,
            "z": -2.56
          }
        }
      },
      "32": {
        "id": "07027182",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.67,
          "start": null,
          "end": null,
          "delay": 103,
          "position": {
            "x": -4.27,
            "y": 1.46,
            "z": -2.14
          }
        }
      },
      "34": {
        "id": "07012095",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.5,
          "start": 0.1,
          "end": 23,
          "delay": 103,
          "position": {
            "x": -2.82,
            "y": 2.13,
            "z": -1.19
          }
        }
      },
      "35": {
        "id": "07039356",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.67,
          "start": 0.1,
          "end": 23,
          "delay": 102,
          "position": {
            "x": -5.01,
            "y": 2.21,
            "z": 0.23
          }
        }
      },
      "36": {
        "id": "07002113",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.33,
          "start": 0.1,
          "end": 34,
          "delay": 48,
          "position": {
            "x": -1.69,
            "y": 1.6,
            "z": -2.6
          }
        }
      },
      "37": {
        "id": "07004054",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.17,
          "start": 6,
          "end": 22,
          "delay": 139,
          "position": {
            "x": 0.07,
            "y": 1.44,
            "z": -3.17
          }
        }
      },
      "38": {
        "id": "07012095",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.83,
          "start": null,
          "end": null,
          "delay": 171,
          "position": {
            "x": 3.35,
            "y": 1.61,
            "z": -1.92
          }
        }
      },
      "39": {
        "id": "07039356",
        "mixerSettings": {
          "isLooping": false,
          "volume": 0.83,
          "start": null,
          "end": null,
          "delay": 171,
          "position": {
            "x": 1.67,
            "y": 1.73,
            "z": -2.95
          }
        }
      }
    }
  }),
  staticSound: {
    src: '/assets/visit-from-st-nicholas.mp3',
    loop: false,
    delay: 0,
  },
  backgroundImageUrl: '/assets/143539289_lel.small.jpg',
  backgroundImageOffsetAngle: 180,
  duration: 200,
  foregroundbaseColor: '#416568',
  particleConfiguration: snowParticleConfiguration,
};

export default christmas;
