import aframe, { THREE } from 'aframe';
import Hammer from 'hammerjs';
import { keepOriginalDistance, pressToMoveOnMobile } from '../../config';

aframe.registerSystem('handle-events', {
  init: function () {
    // next target is updated in response to intersection events (mouseenter/leave).
    this.nextTarget = null;

    // current target is updated when starting or ending an interaction
    // current target determines highlighting
    this.currentTarget = null;

    // whether we are currently interacting with a target - ignore selection changes during this.
    this.currentInteraction = false;

    // select the a-entity used as a position target, positioned relatively to the camera.
    this.positionTargetEl = document.getElementById('position-target');
    this.cursorMatrixWorld = new THREE.Matrix4();

    // select the element that will be capturing touch events. The InteractionTarget is part of
    // the DOM overlay and only rendered if the automaticallyEnterAR setting is active.
    let touchTargetEl = document.querySelector('.InteractionTarget')

    // if the InteractionTarget does not exist, use the webGL canvas created by aframe. Note that
    // this does not output touch events while the AR camera view is active.
    if (!touchTargetEl) {
      touchTargetEl = this.el.sceneEl.canvas;
    }

    this.hammer = new Hammer(touchTargetEl);

    // set current interaction if we have a current target.
    const startInteraction = (type) => {
      if (this.currentTarget) {
        this.currentInteraction = type;

        this.dispatchEditMix('setInteraction', { interaction: type });
        console.log(`started interaction: ${type}`);
      }
    };

    // end current interaction and reset currentTarget to be nextTarget
    const endInteraction = () => {
      // update react when interaction has ended
      if (this.currentInteraction === 'position') {
        // TODO consider also setting orientation so the sphere does not appear to be rotating.
        this.dispatchEditMix('setParameters', {
          index: this.currentTarget.index,
          parameters: {
            position: {
              ...this.currentTarget.worldPositionOverride,
            },
          },
        });
      } else if (this.currentInteraction === 'volume') {
        // TODO only update react when interaction has ended to avoid redraw on every frame?
        this.dispatchEditMix('setParameters', {
          index: this.currentTarget.index,
          parameters: {
            volume: this.nextVolume,
          },
        });
      }

      this.dispatchEditMix('setInteraction', { interaction: undefined });

      console.log(`ended interaction: ${this.currentInteraction}`);

      this.currentInteraction = null;
      this.updateCurrentTarget();
    };

    // 'position' interaction: start on press, end on tap or press
    this.hammer.on('press', (e) => {
      console.log('press');

      if (this.currentInteraction) {
        endInteraction();
      } else if (this.currentTarget) {
        startInteraction('position');

        if (keepOriginalDistance) {
          const distance = this.sceneEl.camera.getWorldPosition().distanceTo(this.currentTarget.el.object3D.getWorldPosition());
          this.positionTargetEl.setAttribute('position', `0 0 ${-distance}`);
        }
      } else if (e.pointerType === 'touch') {
        // forward movement, used by aframe's internal wasd-controls component
        if (pressToMoveOnMobile) {
          window.dispatchEvent(new KeyboardEvent('keydown', { code: 'KeyW' }));
        }
      }
    });

    // not using pressup because press might turn into pan or swipe and then not fire pressup on end!
    ['mouseup', 'touchend', 'mouseout'].forEach((name) => {
      touchTargetEl.addEventListener(name, () => {
        window.dispatchEvent(new KeyboardEvent('keyup', { code: 'KeyW' }));
      });
    });

    // cancel context menu event bubbling because it ends the wasd movement. seems to happen if pressed for a little bit longer.
    touchTargetEl.addEventListener('contextmenu', (e) => {
      e.cancelBubble = true;
      e.preventDefault();
    });

    this.hammer.on('tap', () => {
      console.log('tap');
      if (this.currentInteraction === 'position') {
        endInteraction();
      }
    });

    // 'volume' interaction: pinch to 'scale' volume
    this.hammer.get('pinch').set({ enable: true });

    this.hammer.on('pinchstart', () => {
      startInteraction('volume');
    });

    this.hammer.on('pinchmove', (e) => {
      if (this.currentInteraction === 'volume') {
        this.nextVolume = -0.3 + Math.min(1.3, Math.max(0.3, e.scale));
      }
    });

    this.hammer.on('pinchend', (e) => {
      endInteraction();
    });
  },
  update: function (oldData) {
    console.log('handle-events update', oldData, this.data);
  },
  dispatchEditMix: function (type, contents) {
    this.el.dispatchEvent(new CustomEvent('dispatchEditMix', { detail: { type, ...contents } }));
  },
  updateCurrentTarget: function () {
    if (!this.currentInteraction) {
      if (this.nextTarget) {
        // Next target is set, and not currently in an active interaction. update.
        // Make the system and React aware that the selected sound has changed
        this.dispatchEditMix('setSelection', { index: this.nextTarget.index });
        this.currentTarget = this.nextTarget;
      } else if (this.currentTarget) {
        // Don't have a next target but still have a current target, and not in an active
        // interaction. clear currentTarget to match nextTarget.
        // Make the system and React aware that the sound has been deselected
        this.dispatchEditMix('clearSelection', { index: this.currentTarget.index });
        this.currentTarget = null;
      }
    }
  },
  enterTarget: function (target) {
    this.nextTarget = target;
    this.updateCurrentTarget();
  },
  leaveTarget: function (target) {
    if (this.nextTarget === target) {
      this.nextTarget = null;
      this.updateCurrentTarget();
    }
  },
  tick: function () {
    if (this.currentInteraction === 'volume') {
      if (this.currentTarget && this.nextVolume !== null) {
        this.currentTarget.setVolume(this.nextVolume);
        this.nextVolume = null;
      }
    }
    if (this.currentInteraction === 'position') {
      if (this.currentTarget) {
        // manually multiplying the world and local transforms together to set the world transform
        // of the moved entity, because getMatrixWorld does not work while XR device is presenting.

        // copy the camera world matrix into the new cursor transform (camera position/orientation)
        this.cursorMatrixWorld.copy(this.sceneEl.camera.matrixWorld);

        // multiply it by the local transform of the cursor (its position relative to the camera)
        this.cursorMatrixWorld.multiply(this.positionTargetEl.object3D.matrix);

        // Set the current target's position from the calculated cursor world matrix
        this.currentTarget.worldPositionOverride.setFromMatrixPosition(this.cursorMatrixWorld);
        this.currentTarget.updateResonancePosition();
      }
    }
  },
  remove: function () {
    // remove event handlers when the system is removed
    if (this.hammer) {
      this.hammer.destroy();
    }
  }
});

aframe.registerComponent('handle-events', {
  schema: {
    id: { type: 'string' }, // The SFX id of the sound represented by the entity
    index: { type: 'string' }, // The key for use in simplifiedMixerAssets
  },
  init: function () {
    const {
      el, // <a-entity />
      data, // the component properties defined in the schema
      system, // the system registered with the same name as the component
    } = this;
    const instance = this;
    const { id, index } = data;

    this.id = id;
    this.index = index;
    this.resonanceSource = el.querySelector('a-resonance-audio-src');
    this.box = el.querySelector('a-box');

    // TODO: Hack; exposes the local position of the current object to be set directly by the position interaction.
    // TODO: store this on ending the interaction?
    // TODO this assumes this entity is at top level and has no other transforms to consider (ie will not work if parented to an anchor?)
    this.worldPositionOverride = this.el.object3D.position;

    // listeners on an individual component
    this.listeners = {
      mouseenter: function () {
        // Make the system aware that the selected sound has changed
        system.enterTarget(instance);
      },
      mouseleave: function () {
        system.leaveTarget(instance);
      },
    };

    Object.entries(this.listeners).forEach(([name, handler]) => {
      el.addEventListener(name, handler);
    });

    // TODO remove from system too? in case an interaction is in progress?
  },
  remove: function () {
    const {
      el,
      listeners,
    } = this;

    if (!listeners) {
      return;
    }

    Object.entries(listeners).forEach(([name, handler]) => {
      el.removeEventListener(name, handler);
    });
  },
  setVolume: function (volume) {
    this.resonanceSource.setAttribute('gain', volume);
  },
  tick: function () {
    // TODO avoid using get/setAttribute on every tick for these

    // Hover state: highlight red if this is the current interaction target.
    if (this.system.currentTarget === this) {
      this.box.setAttribute('color', 'red');
    } else {
      this.box.setAttribute('color', 'white');
    }

    // Volume: change scale to represent volume/gain between 0.2 (volume = 0) and 1
    const scale = 0.8 * this.resonanceSource.getAttribute('gain') + 0.2;
    this.el.setAttribute('scale', `${scale} ${scale} ${scale}`);
  },
  updateResonancePosition: function () {
    // TODO force resonance component to set an updated position, if any
    this.resonanceSource.components['resonance-audio-src'].updateResonancePosition();
  },
});

