import winter from './winter.js';
import christmas from './christmas.js';
import tropical from './tropical.js';
import coast from './coast.js';
import dawn from './dawn.js';

const presets = [
  winter,
  christmas,
  tropical,
  dawn,
  coast,
];

export default presets;
