import presetsTaster from './presets/presets-taster';

export const presets = presetsTaster;

export const showSettings = false;

export const defaultSettings = {
  automaticallyEnterAR: false,
  enableMetadataFetching: true,
  showDebugInfo: false,
  showForeground: true,
  showBackground: true,
  showParticles: true,
};

export const showTasterBadge = true;

export const enableAnalytics = true;

export const pilotId = 'soundscape-explorer';

export const counterName = 'taster.pilot.soundscapeexplorer';

export const shufflePresets = false;

export const maxImportedSounds = 35;

export const keepOriginalDistance = true;

export const pressToMoveOnMobile = false;
