import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { Switch, Route, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import Scene from './Scene';
import SelectedSoundOverlay from './SelectedSoundOverlay.js';
import HelpModal from './HelpModal.js';
import BottomNavigation from './BottomNavigation.js';
import { useDispatch, useMixObject, useSettings } from '../App';
import { decodeMixString } from '../mixString';
import ErrorMessage from '../components/ErrorMessage';
import LoadingIndicator from './LoadingIndicator';
import InteractionTarget from './InteractionTarget';

const makeInteractionEvent = (scene, type) => ({
  type: 'LOG_CUSTOM',
  custom: {
    actionName: 'listen',
    actionType: 'interaction',
    metadata: {
      scene,
      type,
    }
  },
});

const makePlayEvent = (scene, time) => ({
  type: 'LOG_CUSTOM',
  custom: {
    actionName: 'listen',
    actionType: 'play',
    metadata: {
      scene,
      time,
    }
  },
});

const makeLoadingEvent = (scene, time, loaded) => ({
  type: 'LOG_CUSTOM',
  custom: {
    actionName: 'listen',
    actionType: 'loading',
    metadata: {
      scene,
      time,
      loaded,
    }
  },
});

const makeEndedEvent = (scene) => ({
  type: 'LOG_CUSTOM',
  custom: {
    actionName: 'listen',
    actionType: 'ended',
    metadata: {
      scene,
    }
  },
});

const initialEditedMix = {
  currentInteraction: undefined,
  selectedIndex: undefined,
  mixObject: {},
};

const editMixReducer = (state, action) => {
  switch (action.type) {
  case 'reset':
    return {
      ...initialEditedMix,
      mixObject: action.mixObject,
    };
  case 'setSelection':
    return {
      ...state,
      selectedIndex: action.index,
    };
  case 'clearSelection':
    if (state.selectedIndex === action.index) {
      return {
        ...state,
        selectedIndex: undefined,
      };
    }
    return state;
  case 'setInteraction':
    return {
      ...state,
      currentInteraction: action.interaction,
    };
  case 'setParameters':
    return {
      ...state,
      mixObject: {
        simplifiedMixerAssets: {
          ...state.mixObject.simplifiedMixerAssets,
          [action.index]: {
            ...state.mixObject.simplifiedMixerAssets[action.index],
            mixerSettings: {
              ...state.mixObject.simplifiedMixerAssets[action.index].mixerSettings,
              ...action.parameters,
            }
          },
        }
      },
    };
  default:
    return state;
  }
};

const ListenPage = ({ presets }) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { id } = useParams();
  const {
    automaticallyEnterAR,
  } = useSettings();

  const dispatch = useDispatch();

  const [editedMix, dispatchEditMix] = useReducer(editMixReducer, initialEditedMix);

  const [preset, setPreset] = useState(null);
  const [error, setError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loadProgress, setLoadProgress] = useState({ total: 0, progress: 0 });

  const [playedOnce, setPlayedOnce] = useState(false);
  // TODO playbackFailed only _needs_ to be initially true in Safari to ask for permissions
  const [playbackFailed, setPlaybackFailed] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [loadingStartTime, setLoadingStartTime] = useState(0);
  const [playStartTime, setPlayStartTime] = useState(0);
  const [showEnded, setShowEnded] = useState(false);

  const handleLoadProgress = useCallback((progress, total) => {
    console.log(`${progress} / ${total} loaded`);
    setLoadProgress({ progress, total });
  }, [setLoadProgress]);

  const mixObject = useMixObject();

  const handleStartPlaybackGesture = useCallback(async () => {
    if (playedOnce) {
      return;
    }

    // this must be called in an event handler in safari; a useEffect might not qualify.
    try {
      await Promise.all([
        (async () => {
          // 1. start some audio playback to lift autoplay restrictions
          await Promise.all([...document.querySelectorAll('audio')].map(async (audioEl) => {
            await audioEl.play();
            await audioEl.pause();
          }));
        })(),

        (async () => {
          // 2. resume the audio context
          const { audioContext } = document.querySelector('a-resonance-audio-room').components['resonance-audio-room'];

          // create an oscillator source and connect it, so that some audio is playing (needed to resume audio context in safari?)
          const source = audioContext.createOscillator();
          source.connect(audioContext.destination);
          source.start();

          audioContext.resume();

          // remove the source
          source.stop();
          source.disconnect();
        })(),

        (async () => {
          // 3. request permission to use device orientation
          // NOTE requestPermission only exists in safari, other browsers either send the events
          // or don't, with seemingly no way of knowing.
          if (DeviceMotionEvent && DeviceMotionEvent.requestPermission) {
            const permissionState = await DeviceMotionEvent.requestPermission();
            if (permissionState !== 'granted') {
              setError('Could not access the device motion sensors. Please check your browser settings or permissions.');
              throw new Error(`DeviceMotion permission state is ${permissionState}`);
            }
          }
        })(),
      ]);

      // 4. start playback properly as we should now be allowed.
      setPlaybackFailed(false);

      setPlayedOnce(true);
      setPlayStartTime(Date.now());
    } catch (e) {
      console.error('handleStartPlaybackGesture: at least one of the things didn\'t work.', e);
    }
  }, [playedOnce, setPlayedOnce, setPlaybackFailed, setPlayStartTime]);

  // set the ended timer when starting to play
  useEffect(() => {
    let timeout;

    if (playStartTime && preset && preset.duration) {
      timeout = setTimeout(() => {
        // Pause all remaining sound sources. NB this is a hack that will not catch any sounds that are scheduled to start in the future.
        document.querySelectorAll('audio').forEach(el => el.pause());
        // Show the ended modal.
        setShowEnded(true);
        // send the analytics event.
        dispatch(makeEndedEvent(preset.id));
      }, Date.now() + preset.duration * 1000 - playStartTime);
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [preset, playStartTime, setShowEnded]);

  const handlePlaybackFailed = useCallback(() => {
    setPlaybackFailed(true);
  }, [setPlaybackFailed]);

  const handleProgress = useCallback((newProgress) => {
    setProgress(newProgress);
  }, [setProgress]);

  const handleLoaded = useCallback(() => {
    setLoaded(true);
  }, [setLoaded]);

  useEffect(() => {
    if (id === 'custom') {
      setPreset({
        id: 'custom',
        environmentConfig: 'preset: default',
      });
      setError(false);
    } else {
      const newPreset = presets.find(({ id: mixId }) => mixId === id);

      if (!newPreset) {
        setError('Sorry, this preset can\'t be found');
        return;
      }

      if (!newPreset.mixString) {
        setError('Sorry, this preset can\'t be found');
        return;
      }

      setPreset(newPreset);

      dispatch({
        type: 'SET_MIX_OBJECT',
        mixObject: decodeMixString(newPreset.mixString),
      });
    }
  }, [dispatch, id, presets]);

  useEffect(() => {
    if (mixObject) {
      dispatchEditMix({ type: 'reset', mixObject });
    }
  }, [dispatchEditMix, mixObject]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'SET_MIX_OBJECT',
        mixObject: undefined,
      });
    };
  }, [dispatch]);

  // Effect to report loading complete
  useEffect(() => {
    if (!preset) {
      return;
    }

    const report = (time) => {
      console.log('reporting loaded', loaded, time);
      dispatch(makeLoadingEvent(preset.id, time, loaded));
    };

    if (!loaded) {
      if (loadingStartTime === 0) {
        setLoadingStartTime(Date.now());
        report(0);
      }
    } else {
      report(Math.round((Date.now() - loadingStartTime) / 1000));
    }
  }, [preset, loaded, loadingStartTime, dispatch]);

  // Effect to report playback progress within the scene
  useEffect(() => {
    if (!playedOnce) {
      return;
    }

    if (!preset) {
      return;
    }

    const scene = preset.id;
    const start = Date.now();

    let lastReport = 0;

    const reportProgress = () => {
      const time = Math.round((Date.now() - start) / 1000);

      // report every 10 seconds for the first minute, then only every minute.
      if (time <= 60 || time - lastReport >= 60) {
        dispatch(makePlayEvent(scene, time));
        lastReport = time;
      }
    };

    reportProgress();

    const interval = setInterval(reportProgress, 10000);

    return () => {
      console.log('clearing play event timeout');
      reportProgress();
      clearInterval(interval);
    };
  }, [preset, dispatch, playedOnce]);


  useEffect(() => console.log('handleLoaded changed'), [handleLoaded]);
  useEffect(() => console.log('handleLoadProgress changed'), [handleLoadProgress]);
  useEffect(() => console.log('mixObject changed'), [mixObject]);
  useEffect(() => console.log('dispatchEditMix changed'), [dispatchEditMix]);
  useEffect(() => console.log('playbackFailed changed'), [playbackFailed]);
  useEffect(() => console.log('handlePlaybackFailed changed'), [handlePlaybackFailed]);
  useEffect(() => console.log('handleProgress changed'), [handleProgress]);
  useEffect(() => console.log('preset changed'), [preset]);


  // return early if there was an error
  if (error) {
    return <ErrorMessage title={error} recoverHref={'/'} />
  }

  // this condition does not set error state so has to be checked separately
  if (id === 'custom' && !mixObject) {
    return (
      <ErrorMessage
        title="No custom mix loaded."
        description="If you were trying to open a custom mix, make sure to get the link from the 'Info' function. Links copied from the browser address bar may not work."
        recoverLabel="Try one of our mixes"
        recoverHref={'/'}
      />
    );
  };

  const staticSound = preset && preset.staticSound;

  // Note the #overlay div is referenced by DOM id in Scene.js, so that it is shown in the
  // full-screen AR view
  return (
    <>
      <div id="overlay">
        <Switch>
          <Route path={`${path}/info`}>
            {loaded && !showEnded && <HelpModal onClose={() => history.push(`/listen/${id}`)} preset={preset} mixObject={editedMix.mixObject} />}
          </Route>
        </Switch>

        {!loaded && <LoadingIndicator total={loadProgress.total} progress={loadProgress.progress} />}

        {loaded && playbackFailed && !showEnded && <HelpModal onClose={handleStartPlaybackGesture} preset={preset} playbackFailed />}

        {showEnded && <HelpModal preset={preset} ended />}

        {editedMix.selectedIndex !== undefined && <SelectedSoundOverlay editedMix={editedMix} />}

        <BottomNavigation showProgress={!!staticSound} progress={progress} id={id} />

        {automaticallyEnterAR && <InteractionTarget />}
      </div>

      {mixObject && (
        <Scene
          onLoaded={handleLoaded}
          onLoadProgress={handleLoadProgress}
          mixObject={mixObject}
          dispatchEditMix={dispatchEditMix}
          playbackFailed={playbackFailed}
          onPlaybackFailed={handlePlaybackFailed}
          onProgress={handleProgress}
          preset={preset}
        />
      )}
    </>
  );
};

export default ListenPage;
