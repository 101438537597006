import aframe from 'aframe';

// maximum difference in seconds used for comparisons of start and end times.
const EPSILON = 0.01; // 10ms

aframe.registerSystem('playback-control', {
  init: function() {
    this.components = new Set();
  },
  addComponent: function (component) {
    if (!this.components.has(component)) {
      this.components.add(component);
    }
  },
  removeComponent: function (component) {
    this.components.delete(component);
  },
  playAllFromStart: function () {
    console.log(`playback-control system playAllFromStart, ${this.components.size} registered components.`);

    Promise.all([...this.components].map(c => c.playFromStart())).catch((e) => {
      console.error('playback-control system dispatching playback failed after error', e);
      this.sceneEl.dispatchEvent(new CustomEvent('playbackFailed'));
    });
  },
});

aframe.registerComponent('playback-control', {
  schema: {
    delay: { type: 'number' },
    start: { type: 'number' },
    end: { type: 'number' },
    loop: { type: 'boolean' },
    audioEl: { type: 'selector' },
  },
  init: function() {
    const {
      audioEl,
      start,
      end,
      loop,
    } = this.data;

    this.system.addComponent(this);

    if (!audioEl) {
      console.error('audio element not found');
      return;
    }

    audioEl.loop = loop;

    this.listeners = {
      'timeupdate': () => {
        const { currentTime, duration, id } = audioEl;

        if (start && currentTime < (start - EPSILON)) {
          audioEl.currentTime = start;
        } else if (!!end && currentTime > (end + EPSILON)) {
          if (loop) {
            audioEl.currentTime = start || 0;
          } else {
            audioEl.pause();
          }
        }

        if (id === 'audio_static') {
          const now = performance.now();
          if (!this.lastProgress || this.lastProgress < now - 1000) {
            this.el.sceneEl.dispatchEvent(new CustomEvent('playbackProgress', { detail: { progress: currentTime/duration } }));
            this.lastProgress = now;
          }
        }
      },
    };

    Object.entries(this.listeners).forEach(([name, handler]) => {
      audioEl.addEventListener(name, handler);
    });
  },
  playFromStart: function() {
    const {
      audioEl,
      start,
      delay,
    } = this.data;

    return new Promise((resolve, reject) => {
      // don't check again if already played once
      if (this.playedOnce) {
        resolve();
        return;
      }

      // start playing
      const promise = audioEl.play();
      if (promise) {
        promise.then(resolve).catch(reject);
      } else {
        // fallback for return value not being a promise in older browsers, assume it worked.
        resolve();
      }
    }).then(() => {
      this.playedOnce = true;

      audioEl.pause(); // if successfully played to check autoplay restrictions, pause now.
      audioEl.currentTime = start || 0;

      if (delay) {
        clearTimeout(this.delayTimeout);
        audioEl.pause();

        this.delayTimeout = setTimeout(() => {
          audioEl.play();
        }, delay * 1000);
      } else {
        audioEl.play();
      }
    });
  },
  remove: function() {
    const { audioEl } = this.data;
    if (audioEl && this.listeners) {
      Object.entries(this.listeners).forEach(([name, handler]) => {
        audioEl.removeEventListener(name, handler);
      });
    }

    clearTimeout(this.delayTimeout);
    this.system.removeComponent(this);
  },
});
