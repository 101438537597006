import React from 'react';

// Icon paths from https://github.com/bbc/gel-iconography/tree/main/dist
const IconDefinitions = () => (
  <svg viewBox="0 0 32 32" style={{ display: 'none' }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path id="gel-audio" d="M26.9 16c0-3.9-1.5-7.4-3.9-10.1l-1.8 1.8c2 2.2 3.2 5.1 3.2 8.3s-1.2 6.1-3.2 8.3l1.8 1.8c2.4-2.7 3.9-6.2 3.9-10.1zm2.6 0c0 4.6-1.8 8.8-4.7 11.9l1.8 1.8C29.9 26.1 32 21.3 32 16S29.9 5.9 26.5 2.3l-1.8 1.8c3 3.1 4.8 7.3 4.8 11.9zm-23 3.9V12H1.2c-.7 0-1.2.5-1.2 1.2v5.4c0 .7.5 1.2 1.2 1.2h5.3zM.3 15.1l2.4 1.4 11.9-7v13L3.5 16.1l-2.4 1.4L17 26.7V5.3L.3 15.1z"/>
      <path id="gel-exit" d="M13.9 12h-2.1V4.1H32v23.7H11.8v-8.1h2.1v6h16V6.2h-16V12zm9.1 5H2.7l.3-.9 5.4 5.4L7 22.9l-7-7 7-7 1.4 1.5L3 15.8l-.3-.9H23V17z"/>
      <path id="gel-help" d="M15.8 26.7c-1.7 0-2.6-1-2.6-2.3s.9-2.3 2.6-2.3c1.7 0 2.6 1 2.6 2.3s-.9 2.3-2.6 2.3zm-1.9-6.9v-.3c0-1.8.6-3.1 2.4-4.3 1.8-1.2 2.3-1.7 2.3-2.9 0-1.4-1.2-2.1-3.3-2.1-1.7 0-3.2.4-4.8 1.4V8c1.7-.9 3.6-1.3 5.6-1.3 4.3 0 7 2 7 5.1 0 2.3-1 3.5-3 4.9-1.9 1.3-2.3 1.8-2.3 2.7v.3h-3.9zM16 31c8.5 0 15-6.5 15-15S24.5 1 16 1 1 7.5 1 16s6.5 15 15 15z"/>
      <path id="gel-share" d="M7.8 17L25.3 7l-1.2-2.3L6.6 15l1.2 2zm-1.2 0l17.5 10.3 1.2-2.3L7.8 15l-1.2 2zm5.6-1c0-2.7-2.2-5-5-5-2.7 0-4.9 2.2-4.9 5 0 2.7 2.2 4.9 4.9 4.9 2.8 0 5-2.2 5-4.9zM29.7 5.9c0-2.7-2.2-5-5-5-2.7 0-4.9 2.2-4.9 5 0 2.7 2.2 4.9 4.9 4.9 2.8 0 5-2.2 5-4.9zm0 20.2c0-2.7-2.2-5-5-5-2.7 0-4.9 2.2-4.9 5 0 2.7 2.2 4.9 4.9 4.9 2.8.1 5-2.2 5-4.9z"/>
      <path id="gel-star" d="M18.6 14L16 7l-2.6 7-7.5.3 5.9 4.6-2 7.3L16 22l6.3 4.2-2-7.3 5.9-4.6-7.6-.3zM16 25.1l-9.9 6.1 2.8-11.3L0 12.4l11.6-.9L16 .8l4.4 10.8 11.6.8-8.9 7.5 2.8 11.3-9.9-6.1z"/>
      <path id="gel-shuffle-switch" d="M28.6 6.6h-4.1c-5.5 0-9 4.5-12.1 8.5-3.1 4.1-5.8 7.7-9.8 7.7H1.2v2.6h1.4c5.4 0 8.8-4.6 12-8.7 4.3-5.6 6.4-7.5 10-7.5h4.1V6.6zm-4.1 6.7H27L30.8 8 27 2.6h-2.6L28 7.9l-3.5 5.4zm-7.9 4.5L14.9 20c2.6 2.9 5.6 5.4 9.6 5.4h4.1v-2.6h-4.1c-2.9 0-4.9-1.3-7.9-5zm7.9 11.6H27l3.8-5.3-3.8-5.4h-2.6l3.5 5.4-3.4 5.3zM10.4 14.2L12 12C9.5 9.1 6.6 6.6 2.6 6.6H1.2v2.6h1.4c3.1 0 5.5 2.1 7.8 5z"/>
      <path id="gel-activity" d="M28.5 20.3c.5-.3.6-.7.3-1.1l-3-5.3c-.3-.5-.1-.8.3-1.1.3-.2.7-.2 1.3 0 .9.3 1.6.3 2.4-.2 1.5-.8 2-2.7.7-4.9S27.4 5 25.9 5.8c-.8.5-1.1 1.1-1.4 2-.2.6-.3 1-.6 1.1-.4.2-.8.2-1.1-.3l-3.1-5.4c-.3-.4-.7-.5-1.1-.3l-5 2.9c-.4.3-.4.7-.2 1 .2.2.5.5 1.1.6.9.2 1.5.6 1.9 1.3.9 1.5.4 3.3-1.8 4.6-2.2 1.3-4.1.8-5-.7-.4-.8-.4-1.5-.1-2.3.2-.6.2-1.1 0-1.3-.2-.3-.5-.5-1-.3l-5 2.9c-.5.3-.6.7-.3 1.1l3.1 5.4c.3.5.1.9-.3 1.1-.3.2-.8.2-1.3 0-.9-.2-1.6-.2-2.3.2-1.5.9-2 2.7-.7 4.9s3.1 2.7 4.6 1.9c.8-.4 1.1-1.1 1.3-2 .1-.6.4-.9.6-1.1.4-.2.8-.2 1.1.3l3 5.3c.3.4.7.5 1.1.3l5-2.9c.4-.3.4-.6.2-1-.2-.2-.5-.5-1.1-.6-.9-.2-1.5-.5-2-1.3-.9-1.5-.3-3.4 1.9-4.7 2.2-1.3 4.1-.8 4.9.7.4.8.5 1.5.2 2.3-.2.6-.2 1.1 0 1.3.2.4.5.5 1 .3l5-2.8z"/>
      <path id="gel-refresh" d="M7.6 22.3l-2 1.4c2.1 3.4 5.8 5.5 10.6 5.5 6.7 0 12.4-4.9 12.4-12.7v-1.8h-2.5v1.8c0 6.3-4.4 10.2-10.1 10.2-3.9.1-6.9-1.8-8.4-4.4zM32 18v-2.2l-4.7-3.3-4.7 3.3V18l4.7-3.1L32 18zm-7.5-8.3l2-1.4C24.4 5 20.7 2.8 15.9 2.8 9.2 2.8 3.5 7.7 3.5 15.5v1.8H6v-1.8C6 9.1 10.4 5.2 16.1 5.2c3.9 0 6.8 1.9 8.4 4.5zM0 14v2.2l4.7 3.3 4.7-3.3V14l-4.7 3.1L0 14z"/>
      <path id="gel-confirm" d="M32 7.2l-2.5-2.4L11 23.3h2L2.4 12.6 0 15.1l12 12.1 20-20z"/>
      <path id="gel-close" d="M30 4.6l-2.8-2.8L2 27.4l2.8 2.8L30 4.6zM4.8 1.8L1.9 4.7l25.2 25.5 2.9-2.9L4.8 1.8z"/>
      <path id="gel-collection" d="M5.5 26H26V5.4h-2.4v18.2H5.5V26zM1 21.1h20V1H1v20.1zM9.8 31H31V9.7h-2.4v18.9H9.8V31z"/>
      <path id="gel-play" d="M29 16L5.8 1v30L29 16z"/>
      <path id="gel-duplicate" d="M29.7 8.5H9.8V31h19.9V8.5zm-17.2 2.6h14.6v17.3H12.5V11.1zM11 23.5v-2.6H4.9V3.6h14.6v6.3h2.7V1H2.3v22.6H11z"/>
      <path id="gel-loading" d="M29 8.3l-2.5 1.5c1.1 1.8 1.6 3.9 1.6 6.2 0 6.9-5.3 12.2-12.1 12.2-6.9 0-12.1-5.3-12.1-12.2S9.1 3.8 16 3.8V1C7.5 1 1 7.5 1 16s6.5 15 15 15 15-6.5 15-15c0-2.9-.7-5.5-2-7.7z"/>
    </defs>
  </svg>
);

export default IconDefinitions;
