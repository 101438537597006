import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PageContainer from '../components/PageContainer';
import PageHeader from '../components/PageHeader';
import Button from '../components/Button';
import Icon from '../components/Icon';
import TextInput from '../components/TextInput';
import { decodeMixUrl } from '../mixString';
import { useMixObject, useSoundMetadata, useDispatch } from '../App';
import { maxImportedSounds } from '../config';

/* Example link:
https://sound-effects.bbcrewind.co.uk/miximport?mixstring=eyJzaW1wbGlmaWVkTWl4ZXJBc3NldHMiOnsiMSI6eyJpZCI6Ik5IVTA1MDE4MTY1IiwibWl4ZXJTZXR0aW5ncyI6eyJpc0xvb3BpbmciOmZhbHNlLCJ2b2x1bWUiOjF9fX19
*/

const makeImportEvent = (numObjects) => ({
  type: 'LOG_CUSTOM',
  custom: {
    actionName: 'import',
    actionType: 'mix',
    metadata: {
      numObjects,
    }
  },
});

const ImportPage = () => {
  const history = useHistory();
  const [inputLink, setInputLink] = useState('');
  const [valid, setValid] = useState(false);
  const mixObject = useMixObject();
  const soundMetadata = useSoundMetadata();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const newMixObject = decodeMixUrl(inputLink);
      if (Object.entries(newMixObject.simplifiedMixerAssets).length > maxImportedSounds) {
        throw new Error('too many sounds');
      }
      setValid(true);
      dispatch({
        type: 'SET_MIX_OBJECT',
        mixObject: newMixObject,
      });
    } catch (e) {
      setValid(false);
    }
  }, [dispatch, inputLink]);

  let soundIds = [];
  if (valid && mixObject) {
    soundIds = [...Object.entries(mixObject.simplifiedMixerAssets)].map(([index, { id }]) => [index, id]);
  }

  const handleListenNow = () => {
    dispatch(makeImportEvent(soundIds.length));
    history.push('/listen/custom');
  }

  return (
    <PageContainer>
      <PageHeader backHref="/">
        <h1>Soundscape Explorer</h1>
        <h2>Make your own</h2>
        <ol>
          <li>Use the <a href="https://sound-effects.bbcrewind.co.uk/" target="_blank" rel="noreferrer">BBC Sound Effects Mixer</a> to find and line up your sounds.</li>
          <li>Click &quot;Share mix&quot; and copy the link.</li>
          <li>Paste it below and click the button to listen!</li>
        </ol>
      </PageHeader>

      <p>It may be best to start experimenting with a few sounds. The number of sounds you can use depends on the computer or device you are using, but should be no more than {maxImportedSounds}.</p>

      <p>The positions of your sounds will be random to start with, but you can reposition them. And you can share your soundscape when you're happy with it.</p>

      <TextInput placeholder="Paste the link to your mix here." onChange={(e) => setInputLink(e.target.value)} value={inputLink} />

      <p style={{textAlign: 'center'}}>
        <Button primary large disabled={!valid} icon="gel-audio" content="Listen now" onClick={handleListenNow} />
      </p>

      {valid && (
        <>
          <p>
            {soundIds.length} sound{soundIds.length !== 1 && 's'} found in your mix. Good to go!
            {' '}
            <Icon name="gel-confirm" />
          </p>
          <ol>
            {soundIds.map(([index, id]) => (
              <li key={index}>{soundMetadata[id]?.description || '(missing data)'}</li>
            ))}
          </ol>
        </>
      )}
    </PageContainer>
  );
};

export default ImportPage;
