const preset = {
  id: 'tropical-beach',
  title: 'Tropical Beach',
  description: 'Sound design by Sian Eleri.',
  backgroundImageUrl: '/assets/163054509_lel.small.jpg',
  imageUrl: '/assets/tropical-preview.jpg',
  imageAlt: 'A palm tree lined sandy beach.',
  mixString: 'eyJzaW1wbGlmaWVkTWl4ZXJBc3NldHMiOnsiMSI6eyJpZCI6Ik5IVTA1MDgwMDY3IiwibWl4ZXJTZXR0aW5ncyI6eyJpc0xvb3BpbmciOmZhbHNlLCJ2b2x1bWUiOjAuODMsInN0YXJ0IjoyLjU3LCJlbmQiOjE1MC44MywiZGVsYXkiOm51bGwsInBvc2l0aW9uIjp7IngiOjEuNzEsInkiOjEuMzksInoiOjAuMzJ9fX0sIjMiOnsiaWQiOiJOSFUwNTA3MzA4NyIsIm1peGVyU2V0dGluZ3MiOnsiaXNMb29waW5nIjp0cnVlLCJ2b2x1bWUiOjAuMzMsInN0YXJ0IjoyLjY2LCJlbmQiOjEwNC4wNSwiZGVsYXkiOm51bGwsInBvc2l0aW9uIjp7IngiOi0yLjU2LCJ5IjoxLjM4LCJ6IjotMS4wNX19fSwiNSI6eyJpZCI6Ik5IVTA1MDcyMDg3IiwibWl4ZXJTZXR0aW5ncyI6eyJpc0xvb3BpbmciOmZhbHNlLCJ2b2x1bWUiOjEsInN0YXJ0IjoxNC41OSwiZW5kIjoyMy4wMiwiZGVsYXkiOjUsInBvc2l0aW9uIjp7IngiOi0yLjIxLCJ5Ijo1LjA0LCJ6IjotMi40fX19LCI2Ijp7ImlkIjoiMDcwNDcxNDgiLCJtaXhlclNldHRpbmdzIjp7ImlzTG9vcGluZyI6ZmFsc2UsInZvbHVtZSI6MSwic3RhcnQiOm51bGwsImVuZCI6bnVsbCwiZGVsYXkiOjUsInBvc2l0aW9uIjp7IngiOi0wLjY1LCJ5IjoyLjY0LCJ6IjoyLjg1fX19fX0',
  duration: 161,
};

export default preset;
