import React from 'react';
import Icon from '../components/Icon';
import './LoadingIndicator.css';

const LoadingIndicator = ({
  progress,
  total,
}) => {

  return (
    <div className="LoadingIndicator">
      <p>
        <span className="LoadingIndicator-icon">
          <Icon name="gel-loading" />
        </span>
      </p>
      <p>Loading&hellip;</p>
      {total && (
        <p>{progress}/{total}</p>
      )}
    </div>
  );
};

export default LoadingIndicator;
