import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageViewLogger = ({
  logViewEvent,
}) => {
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
    const [, page, presetId, section] = location.pathname.split('/');

    if (page === '') {
      logViewEvent('index');
    } else if (page === 'listen') {
      logViewEvent('listen', presetId, section);
    } else {
      logViewEvent(page);
    }
  }, [logViewEvent, location]);

  return null;
};

export default PageViewLogger;
