import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PageContainer from '../components/PageContainer';
import PageHeader from '../components/PageHeader';
import Button from '../components/Button';
import Icon from '../components/Icon';
import { useMixObject, useDispatch } from '../App';
import { decodeMixString } from '../mixString';
import { maxImportedSounds } from '../config';

const makeLinkEvent = (numObjects) => ({
  type: 'LOG_CUSTOM',
  custom: {
    actionName: 'link',
    actionType: 'mix',
    metadata: {
      numObjects,
    }
  },
});

const LinkPage = (props) => {
  const { search } = useLocation();
  const mixString = new URLSearchParams(search).get('mixstring');
  const history = useHistory();

  const [valid, setValid] = useState(false);

  const mixObject = useMixObject();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const newMixObject = decodeMixString(mixString);
      if (Object.entries(newMixObject.simplifiedMixerAssets).length > maxImportedSounds) {
        throw new Error('too many sounds');
      }
      setValid(true);
      dispatch({
        type: 'SET_MIX_OBJECT',
        mixObject: newMixObject,
      });
    } catch (e) {
      setValid(false);
    }
  }, [dispatch, mixString]);

  let soundIds = [];

  if (valid && mixObject) {
    soundIds = [...Object.values(mixObject.simplifiedMixerAssets)].map(({ id }) => id);
  }

  const handleListenNow = () => {
    dispatch(makeLinkEvent(soundIds.length));
    history.push('/listen/custom');
  }

  return (
    <PageContainer>
      <PageHeader backHref="/">
        <h1>Soundscape Explorer</h1>
        <h2>Listen to a shared mix</h2>

        {valid
          ? (
            <>
              <p>The soundscape was created by a user of the <a href="https://sound-effects.bbcrewind.co.uk/" target="_blank" rel="noreferrer">BBC Sound Effects Mixer</a>, and has not been reviewed by the BBC. It contains {soundIds.length} sound{soundIds.length !== 1 && 's'}.</p>
            </>
          )
          : (
            <p>Sorry, the link you entered does not seem to contain a valid mix. Please check with the person who sent you the link.</p>
          )}
      </PageHeader>

      {valid && (
        <p style={{textAlign: 'center'}}>
          <Button primary large disabled={!valid} icon="gel-audio" content="Listen now" onClick={handleListenNow} />
        </p>
      )}

      <p>You can also create your own soundscape, or listen to a few examples made by the BBC.</p>

      <p style={{textAlign: 'center'}}>
        <Button secondary content="Find out more" onClick={() => history.push('/')} />
      </p>
    </PageContainer>
  );
};

export default LinkPage;
