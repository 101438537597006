import React from 'react';
import classnames from 'classnames'
import './Grid.css';

const Grid = ({ children, className }) => {
  return (
    <div className={classnames('Grid', className)}>
      {children}
    </div>
  );
};

export default Grid;
