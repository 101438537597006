import React from 'react';
import { Link } from 'react-router-dom';

import Grid from '../components/Grid';
import GridCell from '../components/GridCell';
import Thumbnail from '../components/Thumbnail';

function ChooseSoundscape(props) {
  const {
    presets,
  } = props;

  return (
    <Grid className="two">
      {presets.map(({ id, title, description, imageUrl, imageAlt }) => (
        <GridCell key={id}>
          <Link to={`/listen/${id}`} className="Link-block">
            <Thumbnail title={title} description={description} imageUrl={imageUrl} imageAlt={imageAlt} icon="gel-audio" />
          </Link>
        </GridCell>
      ))}
      <GridCell>
        <Link to={`/import`} className="Link-block">
          <Thumbnail title="Make your own" description="Find out how to use the BBC Sound Effects Mixer to create your own soundscape." imageUrl="/assets/custom.jpg" imageAlt="Photo of an audio mixing desk" icon="gel-activity" />
        </Link>
      </GridCell>
    </Grid>
  );
}

export default ChooseSoundscape;
