import React, { useState } from 'react';
import classnames from 'classnames';
import Button from '../components/Button.js';
import TextInput from '../components/TextInput.js';
import Icon from '../components/Icon.js';
import Grid from '../components/Grid.js';
import GridCell from '../components/GridCell.js';
import './HelpModal.css';
import { encodeMixUrl } from '../mixString';
import { useDispatch } from '../App';
import { useHistory } from 'react-router-dom';

const makePromoteEvent = (scene, promote) => ({
  type: 'LOG_CUSTOM',
  custom: {
    actionName: 'listen',
    actionType: 'promote',
    metadata: {
      scene,
      promote,
    }
  },
});

const HelpModal = ({
  preset,
  onClose,
  playbackFailed,
  mixObject,
  ended,
}) => {
  const history = useHistory();
  const [showSharingLink, setShowSharingLink] = useState(false);
  const [copied, setCopied] = useState(false);
  const [rated, setRated] = useState(false);
  const dispatch = useDispatch();

  const originalMixUrl = preset.id !== 'custom' && `${window.location.origin}/listen/${preset.id}`;
  const mixUrl = mixObject && encodeMixUrl(mixObject);

  const useCustomSharingLink = !!mixUrl;

  const handleShare = () => {
    if (useCustomSharingLink) {
      navigator.share({
        title: 'Listen to my custom soundscape mix',
        url: mixUrl,
      });
    } else {
      navigator.share({
        title: `Listen to the "${preset.title}" soundscape`,
        url: originalMixUrl,
      });
    }
  };

  const handleCopy = async () => {
    // TODO copying of mix url
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(mixUrl);
        setCopied(true);
      } catch (e) {
        console.error('could not copy to clipboard', e);
      }
    }
  };

  const handlePromote = (promote) => {
    dispatch(makePromoteEvent(preset.id, promote));
    setRated(true);
  };

  const imageUrl = preset && preset.imageUrl;

  const showInstructions = !playbackFailed && !ended;
  const showSharing = showInstructions || rated;

  return (
    <div className="HelpModal">
      <button className="HelpModal-fade" onClick={onClose} />

      <div className="HelpModal-content">
        {preset
          ? (
            <>
              <h1>{preset.title}</h1>
              <p>{preset.description}</p>
            </>
          )
          : (
            <>
              <h1>Custom mix</h1>
              <p>This soundscape was created by a user of the BBC Sound Effects library and has not been reviewed by the BBC.</p>
            </>
          )
        }

        {onClose && (
          <button onClick={onClose} className="HelpModal-close">
            <Icon name="gel-close" />
          </button>
        )}

        {ended && (
          <>
            <hr />
            {!rated ? (
              <>
                <p>Thanks for listening.</p>
                <p>How likely are you to recommend <i>Soundscape Explorer</i> to a friend?</p>
                <p className="HelpModal-rating">
                  <Button circle primary content="0" onClick={() => handlePromote(0)} />
                  <Button circle primary content="1" onClick={() => handlePromote(1)} />
                  <Button circle primary content="2" onClick={() => handlePromote(2)} />
                  <Button circle primary content="3" onClick={() => handlePromote(3)} />
                  <Button circle primary content="4" onClick={() => handlePromote(4)} />
                  <Button circle primary content="5" onClick={() => handlePromote(5)} />
                  <Button circle primary content="6" onClick={() => handlePromote(6)} />
                  <Button circle primary content="7" onClick={() => handlePromote(7)} />
                  <Button circle primary content="8" onClick={() => handlePromote(8)} />
                  <Button circle primary content="9" onClick={() => handlePromote(9)} />
                  <Button circle primary content="10" onClick={() => handlePromote(10)} />
                </p>
                <p class="HelpModal-small">0 – least likely to 10 – very likely</p>
              </>
            ) : (
              <>
                <p>Thanks for your feedback.</p>

                <p className="HelpModal-action">
                  <Button secondary icon="" content="Return to homepage" onClick={() => history.push('/')} />
                </p>
              </>
            )}
          </>
        )}

        {playbackFailed && (
          <>
            <div className={classnames('HelpModal-action', { 'HelpModal-image': !!imageUrl })}>
              {imageUrl && (
                <img alt="" src={imageUrl} />
              )}
              <div className="HelpModal-image-content">
                <Button large primary icon="gel-play" content="Start listening" onClick={onClose} />
              </div>
            </div>
            <p className="HelpModal-small">You may be asked to allow use of your device sensors so that you can explore and interact with the virtual soundscape.</p>
            <p className="HelpModal-small">This experience works best with headphones. Please make sure you are in a safe space and aware of your surroundings while using it.</p>
          </>
        )}
        {showInstructions && (
          <>
            <Grid className="three HelpModal-instructions">
              <GridCell>
                <img width="100" height="80" alt="A phone screen showing a small circle (the cursor) intersecting a larger circle or sphere (the sound source); arrows indicate that the phone can be moved to explore the scene." src="/assets/select-2.svg" />
                <p className="HelpModal-small">Point at a sphere to select a sound source.</p>
              </GridCell>

              <GridCell>
                <img width="100" height="80" alt="A finger pressing on the screen, and a large circle with an arrow indicating that the sound source can be moved." src="/assets/move.svg" />
                <p className="HelpModal-small">Press to pick up a sound and change its direction, then tap to release.</p>
              </GridCell>

              <GridCell>
                <img width="100" height="80" alt="Two fingers touching the screen and moving towards each other to perform a pinch gesture, and a circle representing a sound source." src="/assets/pinch.svg" />
                <p className="HelpModal-small">Pinch and zoom to set a sound's volume.</p>
              </GridCell>
            </Grid>
          </>
        )}
        {showSharing && (
          <>
            {!showSharingLink && (
              <p className="HelpModal-action">
                <Button primary icon="gel-share" content={useCustomSharingLink ? 'Share custom mix' : 'Share'} onClick={() => setShowSharingLink(true)} />
              </p>
            )}

            {showSharingLink && (
              <>
                <p className="HelpModal-action">
                  <TextInput readOnly value={useCustomSharingLink ? mixUrl : originalMixUrl} />
                </p>
                <p className="HelpModal-action">
                  {navigator.share
                    ? (
                      <>
                        <Button primary content="Share" icon="gel-share" onClick={handleShare} />
                        {" "}
                        <Button secondary content="Copy link" icon={copied ? 'gel-confirm' : 'gel-duplicate'} onClick={handleCopy} />
                      </>
                    )
                    : (
                      <Button primary content="Copy link" icon={copied ? 'gel-confirm' : 'gel-duplicate'} onClick={handleCopy} />
                    )}
                </p>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default HelpModal;
