import React from 'react';
import { useHistory } from 'react-router-dom';
import Icon from '../components/Icon.js';
import './BottomNavigation.css';
import classnames from 'classnames';

const BottomNavigation = ({
  id,
  showProgress,
  progress,
}) => {
  const history = useHistory();

  return (
    <>
      <nav
        className={classnames('BottomNavigation', { progress: showProgress })}
        style={showProgress ? { '--progress': `${progress * 100}%` } : undefined}
      >
        <button className="BottomNavigation-button" onClick={() => history.push('/')}>
          <Icon name="gel-exit" />
          <span className="BottomNavigation-label">Exit Scene</span>
        </button>
        <button className="BottomNavigation-button" onClick={() => history.push(`/listen/${id}/info`)}>
          <Icon name="gel-help" />
          <span className="BottomNavigation-label">Info</span>
        </button>
        <button className="BottomNavigation-button" onClick={() => window.open('https://www.bbc.co.uk/taster')}>
          <Icon name="gel-star" />
          <span className="BottomNavigation-label">Feedback</span>
        </button>
      </nav>
    </>
  );
};

export default BottomNavigation;

