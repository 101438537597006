import aframe, { THREE } from 'aframe';

aframe.registerComponent('envelope-visualisation', {
  schema: {
    audioEl: { type: 'selector' },
    id: { type: 'string' },
  },
  objectName: 'envelope-visualisation',
  init: function () {
    fetch(`https://sound-effects-media.bbcrewind.co.uk/waveform/${this.data.id}.json`)
      .then(result => result.json())
      .then((peaks) => {
        this.peaks = peaks;
        this.loaded = true;

        this.el.setObject3D(this.objectName, new THREE.Mesh(
          new THREE.BoxGeometry(0.05, 0.3, 0.05),
          new THREE.MeshBasicMaterial({ color: 0xffffff }),
        ));
      });
  },
  remove: function () {
    this.el.removeObject3D(this.objectName);
  },
  tick: function () {
    const { audioEl } = this.data;
    const obj = this.el.getObject3D(this.objectName);
    if (obj && audioEl) {
      if (!audioEl.paused) {
        const { data, samples_per_pixel, sample_rate } = this.peaks;
        const peak = data[Math.floor(2 * audioEl.currentTime * sample_rate / samples_per_pixel)];

        // obj.scale.y = Math.abs(peak);

        // some averaging to make it look smoother, as the peak won't change every frame
        obj.scale.y = 0.6 * obj.scale.y + 0.4 * Math.abs(peak);
      } else {
        obj.scale.y = 0;
      }
    }
  },
});
