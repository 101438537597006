import React, { useState } from 'react';
import shuffle from 'lodash.shuffle';
import ChooseSoundscape from './ChooseSoundscape';
import PageContainer from '../components/PageContainer';
import PageHeader from '../components/PageHeader';
import Button from '../components/Button';
import Grid from '../components/Grid';
import GridCell from '../components/GridCell';
import Settings from './Settings';
import { showSettings, shufflePresets } from '../config';

const HomePage = (props) => {
  const {
    presets,
    onChange,
  } = props;
  const [shuffledPresets, setShuffledPresets] = useState(presets);
  const [showAll, setShowAll] = useState(!shufflePresets);

  const handleShuffle = () => {
    setShuffledPresets(shuffle([...presets]));
  };

  return (
    <PageContainer>
      <PageHeader>
        <h1>Soundscape Explorer</h1>
        <p>Put on your headphones to explore virtual sound worlds in immersive 3D audio. You can listen to our mixes, or create your own with the BBC Sound Effects archive.</p>
        <p>By listening to a soundscape on this site you are accepting the use of cookies, and agree to our <a href="//www.bbc.co.uk/privacy/information/policy/" target="_blank" rel="noopener noreferrer">privacy policy</a>.</p>
      </PageHeader>

      <ChooseSoundscape presets={shuffledPresets.slice(0, showAll ? shuffledPresets.length : 3)} onChange={onChange} />

      {!showAll && (
        <Grid className="two">
          <GridCell>
            <Button fluid primary icon="gel-shuffle-switch" content="Shuffle" onClick={handleShuffle} />
          </GridCell>
          <GridCell>
            <Button fluid secondary icon="gel-collection" content={`See all ${presets.length}`} onClick={() => setShowAll(true)}/>
          </GridCell>
        </Grid>
      )}

      {showSettings && <Settings />}
    </PageContainer>
  );
};

export default HomePage;
