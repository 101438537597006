const preset = {
  title: 'Spring Awakening',
  description: 'Sound design by Jack Reynolds.',
  id: 'spring-awakening',
  particleConfiguration: 'preset: dust; color: #99ff33; particleCount: 1000',
  backgroundImageUrl: '/assets/undisturbed-underwood.small.jpg',
  imageUrl: '/assets/dawn-preview.jpg',
  imageAlt: 'Forest scene with sun shining through dense trees',
  mixString: 'eyJzaW1wbGlmaWVkTWl4ZXJBc3NldHMiOnsiMSI6eyJpZCI6Ik5IVTA1MDA2MDk5IiwibWl4ZXJTZXR0aW5ncyI6eyJpc0xvb3BpbmciOmZhbHNlLCJ2b2x1bWUiOjAuMzMsInN0YXJ0IjpudWxsLCJlbmQiOm51bGwsInBvc2l0aW9uIjp7IngiOi0xLjA2LCJ5IjoxLjM2LCJ6IjowLjQ3fX19LCIyIjp7ImlkIjoiMDcwMzAwMzUiLCJtaXhlclNldHRpbmdzIjp7ImlzTG9vcGluZyI6ZmFsc2UsInZvbHVtZSI6MC4xNywic3RhcnQiOjAsImVuZCI6MTU5LjIzLCJkZWxheSI6MTAsInBvc2l0aW9uIjp7IngiOi0zLjEzLCJ5IjozLjc4LCJ6IjowLjg4fX19LCIzIjp7ImlkIjoiTkhVMDUwMDgxMTAiLCJtaXhlclNldHRpbmdzIjp7ImlzTG9vcGluZyI6ZmFsc2UsInZvbHVtZSI6MC4zMywic3RhcnQiOjAsImVuZCI6NzIuOSwiZGVsYXkiOjEwLCJwb3NpdGlvbiI6eyJ4IjotMC4yNSwieSI6Mi43NiwieiI6LTIuMzh9fX0sIjUiOnsiaWQiOiJOSFUwNTAzNjAzMiIsIm1peGVyU2V0dGluZ3MiOnsiaXNMb29waW5nIjpmYWxzZSwidm9sdW1lIjowLjgzLCJzdGFydCI6bnVsbCwiZW5kIjpudWxsLCJkZWxheSI6MjAsInBvc2l0aW9uIjp7IngiOi0yLjksInkiOjEuMywieiI6LTMuMDZ9fX0sIjYiOnsiaWQiOiIwNzA2MjAzOSIsIm1peGVyU2V0dGluZ3MiOnsiaXNMb29waW5nIjpmYWxzZSwidm9sdW1lIjowLjE3LCJzdGFydCI6bnVsbCwiZW5kIjpudWxsLCJkZWxheSI6MjUsInBvc2l0aW9uIjp7IngiOi0wLjc5LCJ5IjoxLjYsInoiOi0yLjgxfX19LCI5Ijp7ImlkIjoiTkhVMDUwNzMwMTQiLCJtaXhlclNldHRpbmdzIjp7ImlzTG9vcGluZyI6ZmFsc2UsInZvbHVtZSI6MC41LCJzdGFydCI6bnVsbCwiZW5kIjpudWxsLCJkZWxheSI6NjAsInBvc2l0aW9uIjp7IngiOjEuOTQsInkiOjUuNzMsInoiOi0wLjk3fX19LCIxMCI6eyJpZCI6Ik5IVTA1MDcyMDg2IiwibWl4ZXJTZXR0aW5ncyI6eyJpc0xvb3BpbmciOmZhbHNlLCJ2b2x1bWUiOjAuNSwic3RhcnQiOjAuNiwiZW5kIjo5OS4zNSwiZGVsYXkiOjcwLCJwb3NpdGlvbiI6eyJ4IjowLjMzLCJ5Ijo0LjY2LCJ6IjoxLjMyfX19LCIxMSI6eyJpZCI6Ik5IVTA1MDYxMTM0IiwibWl4ZXJTZXR0aW5ncyI6eyJpc0xvb3BpbmciOmZhbHNlLCJ2b2x1bWUiOjAuNSwic3RhcnQiOjAsImVuZCI6NDMuMjUsImRlbGF5Ijo4MCwicG9zaXRpb24iOnsieCI6LTEuMTMsInkiOjMuNjEsInoiOi0wLjE5fX19LCIxMiI6eyJpZCI6Ik5IVTA1MDA1MTI0IiwibWl4ZXJTZXR0aW5ncyI6eyJpc0xvb3BpbmciOmZhbHNlLCJ2b2x1bWUiOjAuNSwic3RhcnQiOjAsImVuZCI6MzQuODQsImRlbGF5Ijo5MCwicG9zaXRpb24iOnsieCI6LTAuODYsInkiOjQuNDQsInoiOjIuNzh9fX19fQ==',
  duration: 188,
};

export default preset;
