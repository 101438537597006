import React from 'react';
import classnames from 'classnames';
import Icon from './Icon.js';
import './Button.css';

const Button = ({
  onClick,
  content,
  disabled,
  large,
  primary,
  secondary,
  fluid,
  icon,
  circle,
}) => (
  <button
    disabled={disabled}
    onClick={onClick}
    className={classnames('Button', {
      large,
      primary,
      secondary,
      fluid,
      circle,
      icon: !!icon,
    })}
  >
    {icon && <Icon large={large} name={icon} /> }
    {content && <span className="Button-content">{content}</span>}
  </button>
);

export default Button;
