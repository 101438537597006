import React, { Fragment, useState, useRef, useEffect } from 'react';
import './aframeComponents.js';
import { createSounds } from '../mixString.js';
import { useMixObject, useSettings } from '../App';
import { randomPositionDisc, randomColorNear } from '../random';

// Function mediaElementLoaded from aframe https://github.com/aframevr/aframe/blob/master/src/core/a-assets.js
const mediaElementLoaded = (el) => new Promise(function (resolve, reject) {
  if (el.readyState === 4) { return resolve(); }  // Already loaded.
  if (el.error) { return reject(); }  // Error.

  el.addEventListener('loadeddata', checkProgress, false);
  el.addEventListener('progress', checkProgress, false);
  el.addEventListener('error', reject, false);

  function checkProgress () {
    // Add up the seconds buffered.
    var secondsBuffered = 0;
    for (var i = 0; i < el.buffered.length; i++) {
      secondsBuffered += el.buffered.end(i) - el.buffered.start(i);
    }

    // Compare seconds buffered to media duration.
    if (secondsBuffered >= el.duration) {
      resolve();
    }
  }
});

const makeForegroundParams = (color) => new Array(100).fill(0)
  .map((d, i) => ({
    key: i,
    position: randomPositionDisc([0, 0, 0], 6, 25, 0),
    scale: Math.random() * 4 + 0.5,
    color: randomColorNear(color, 0.2),
  }));

const Scene = React.memo(function (props) {
  const {
    dispatchEditMix,
    onPlaybackFailed,
    playbackFailed,
    onProgress,
    onLoadProgress,
    onLoaded,
    preset,
  } = props;

  const {
    automaticallyEnterAR,
    showDebugInfo,
    showForeground,
    showBackground,
    showParticles,
  } = useSettings();

  // Get the current mix object from the app state and create the sound sources
  const mixObject = useMixObject();
  const sounds = createSounds(mixObject);

  const [inAR, setInAR] = useState(false);

  // Get a reference to the scene DOM element and register event handlers if it changes.
  const sceneRef = useRef();

  // Effect to register an event handler on the scene, so that we can dispatch events from aframe
  // systems or components.
  useEffect(() => {
    const scene = sceneRef.current;

    if (!scene) {
      return;
    }

    const handleDispatchEditMix = ({ detail }) => {
      dispatchEditMix(detail);
    };

    scene.addEventListener('dispatchEditMix', handleDispatchEditMix);

    return () => {
      scene.removeEventListener('dispatchEditMix', handleDispatchEditMix);
    }
  });

  // Unused effect to automatically enter AR mode
  useEffect(() => {
    const scene = sceneRef.current;

    if (!scene || playbackFailed) {
      return;
    }

    if (automaticallyEnterAR) {
      try {
        scene.enterAR()
          .then(() => {
            console.log('entered ar');
            setInAR(true);
          }).catch((e) => {
            console.log('failed to enter VR/AR mode', e);
          });
      } catch (e) {
        console.log('failed to enter AR automatically (thrown error)', e);
        // TODO handle this error, perhaps by going back to the home page or showing an overlay
      }
    }
  }, [sceneRef, playbackFailed, automaticallyEnterAR]);

  // Effect to play from start when playbackFailed flag is reset (by closing modal).
  useEffect(() => {
    const scene = sceneRef.current;

    if (!scene) {
      return;
    }

    if (!playbackFailed) {
      scene.systems['playback-control'].playAllFromStart();
    }
  }, [sceneRef, playbackFailed]);

  // Effect to listen for playbackFailed event and passing to parent component.
  useEffect(() => {
    const scene = sceneRef.current;

    if (!scene) {
      return;
    }

    const handlePlaybackFailed = () => {
      onPlaybackFailed();
    };

    const handleProgress = (e) => {
      onProgress(e.detail.progress);
    }

    scene.addEventListener('playbackFailed', handlePlaybackFailed);
    scene.addEventListener('playbackProgress', handleProgress);

    return () => {
      scene.removeEventListener('playbackFailed', handlePlaybackFailed);
      scene.removeEventListener('playbackProgress', handleProgress);
    };
  }, [sceneRef, onPlaybackFailed, onProgress]);

  // useEffect(() => console.log('mixObject'), [mixObject]);
  // useEffect(() => console.log('sounds', sounds.length), [sounds]);
  // useEffect(() => console.log('sceneRef'), [sceneRef]);
  // useEffect(() => console.log('onPlaybackFailed'), [onPlaybackFailed]);
  // useEffect(() => console.log('onProgress'), [onProgress]);

  // Effect to hide the loading overlay when the asset management system reports that all
  // audio files are ready (or timed out).
  useEffect(() => {
    const scene = sceneRef.current;
    const assets = scene.querySelector('a-assets');

    const start = performance.now();

    const handleLoaded = () => {
      console.log(`loaded in ${((performance.now() - start)/1000).toFixed(1)}s`);
      onLoaded();
    };

    // watch for individual load progress the same as aframe does internally to update progress bar
    const audioAssets = assets.querySelectorAll('audio');
    const loadedAssets = new Set();

    let report = true;
    onLoadProgress(0, audioAssets.length);
    audioAssets.forEach((audio) => {
      mediaElementLoaded(audio).then(() => {
        loadedAssets.add(audio);
        if (report) {
          onLoadProgress(loadedAssets.size, audioAssets.length);
        }
      }).catch(() => {
        console.log('media element loading failed');
      })
    });

    // loading screen is hidden when aframe decides it has loaded or timed out regardless of progress above
    assets.addEventListener('loaded', handleLoaded);

    return () => {
      // TODO - should maybe clean up the load mediaElementLoaded promises above too.
      report = false;
      assets.removeEventListener('loaded', handleLoaded);
    };
  }, [sceneRef, onLoaded, onLoadProgress, sounds]);

  useEffect(() => {
    return () => {
      document.querySelector('html').classList.remove('a-fullscreen');
    };
  }, []);

  let allAudio = [];
  let allSources = []

  for (const sound of sounds) {
    let asset = sound.getAudioElement();
    let src = sound.getResonanceElement();

    allAudio.push(asset);
    allSources.push(src);
  }

  // Get additional element configuration from the preset, if present.
  const {
    staticSound,
    particleConfiguration,
    backgroundImageUrl,
    foregroundBaseColor,
    skyColor = '#342446',
    fogColor = '#cccccc',
    environmentConfig,
    backgroundImageOffsetAngle = 0,
  } = preset || {};

  // TODO set up an environment for custom mixes that don't have a preset, share the preset ID with the share link?

  const [foregroundInstances, setForegroundInstances] = useState([]);

  useEffect(() => {
    if (foregroundBaseColor) {
      setForegroundInstances(makeForegroundParams(foregroundBaseColor));
    } else {
      setForegroundInstances([]);
    }
  }, [foregroundBaseColor]);

  const sky = 1;
  const ground = !!foregroundBaseColor;
  const spotlight = !!foregroundBaseColor;

  return (
    <Fragment>
      <a-scene
        loading-screen="enabled: false"
        device-orientation-permission-ui="enabled: false"
        vr-mode-ui={`enabled: ${!automaticallyEnterAR}`}
        stats={false}
        webxr="requiredFeatures: local, local-floor; optionalFeatures: dom-overlay; overlayElement: #overlay; referenceSpaceType: local-floor"
        ref={sceneRef}
        fog={`type: linear; near: 3; far: 200; color: ${fogColor}`}
      >
        <a-camera position="0 1.6 0" look-controls="touchEnabled: false; mouseEnabled: true">
          <a-entity
            raycaster="objects: .clickable;"
            cursor="fuse: false;"
            position="0 0 -0.1"
            geometry="primitive: ring; radiusInner: 0.0008; radiusOuter: 0.0015"
            material={`color: ${showBackground ? '#fff' : '#342446'}`}
          />
          <a-entity
            id="position-target"
            position="0 0 -0.5"
          />
        </a-camera>

        <a-assets timeout={10000}>
          {allAudio}
          {staticSound && (
            <audio src={staticSound.src} id="audio_static" crossOrigin="anonymous" preload="auto" />
          )}
          {backgroundImageUrl && <img id="photosphere" src={backgroundImageUrl} alt="" />}
        </a-assets>

        {staticSound && (
          <a-entity playback-control={`audioEl: #audio_static; delay: ${staticSound.delay}; loop: ${staticSound.loop};`} />
        )}

        <a-resonance-audio-room
          id="room"
          visualize={false}
          position="0 0 0"
          width="0"
          height="0"
          depth="0"
          ambisonic-order="3"
          speed-of-sound="343"
        >
          {allSources}
        </a-resonance-audio-room>

        <a-entity light="type: ambient; color: #BBB" />
        <a-entity light="type: directional; color: #FFF; intensity: 0.2" position="-0.5 1 1" />

        {environmentConfig && !inAR && <a-entity environment={environmentConfig} />}

        {!environmentConfig && !inAR && (
          <a-entity>
            {sky && (showBackground && backgroundImageUrl ? (
              <a-sky material="fog: false" radius="500" position="0 0 0" src="#photosphere" rotation={`0 ${backgroundImageOffsetAngle} 0`} />
            ) : (
              <a-sky material="fog: false" radius="500" position="0 100 0" color={skyColor} />
            ))}

            {ground && (
              <a-plane
                position="0 0 0"
                rotation="-90 0 0"
                width="200"
                height="200"
                material="color: #99a; roughness: 0.75; metalness: 0.5; "
              />
            )}
            {spotlight && <a-entity light="type: point; color: #fca; intensity: 1.7; distance: 50; decay: 1;" position="0 8 0" />}

            {showParticles && particleConfiguration && <a-entity position="0 2.25 0" particle-system={particleConfiguration} />}

            {showForeground && foregroundInstances.map(({ key, scale, position, color }) => (
              <a-entity position={position} scale={`${scale} ${scale} ${scale}`} key={key}>
                <a-cone color={color} radius-top="0" radius-bottom="0.5" height="2" position="0 1.1 0" />
                <a-cylinder color="#664433" height="0.2" radius="0.1" position="0 0.1 0" />
              </a-entity>
            ))}
          </a-entity>
        )}

        {showDebugInfo && (
          <a-entity>
            <a-box color="white" position="0 0 0" scale="0.1 0.1 0.1">
              <a-text position="0.6 0.25 0" value="origin (0 0 0)" />
            </a-box>

            <a-box color="blue" position="0 1.6 -2" scale="0.1 0.1 0.1">
              <a-text position="0.6 0.25 0" value="in front / head height (0 1.6 -2)" />
            </a-box>

            <a-box color="blue" position="0 0 -2" scale="0.1 0.1 0.1">
              <a-text position="0.6 0.25 0" value="in front (0 0 -2)" />
            </a-box>

            <a-box color="green" position="0 4 0" scale="0.1 0.1 0.1">
              <a-text position="0.6 0.25 0" value="above (0 4 0)" />
            </a-box>

            <a-box color="red" position="2 0 0" scale="0.1 0.1 0.1">
              <a-text position="0.6 0.25 0" value="to right (2 0 0)" />
            </a-box>

            <a-box color="red" position="2 1.6 0" scale="0.1 0.1 0.1">
              <a-text position="0.6 0.25 0" value="to right / head height (2 1.6 0)" />
            </a-box>
          </a-entity>
        )}
      </a-scene>
    </Fragment>
  );
});

export default Scene;
