import 'aframe';
import '@digaverse/aframe-resonance-audio-component';
import 'aframe-particle-system-component';
import 'aframe-environment-component';

import './aframe/handle-events.js';
import './aframe/playback-control.js';
import './aframe/envelope-visualisation.js';

// TODO wait for fix from aframe that does not emit thousands of warnings about removed THREE.js getInverse method.
console.warn = () => {};
