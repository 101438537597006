import React from 'react';
import { useHistory } from 'react-router-dom';

import PageContainer from './PageContainer';
import Button from './Button';

import './ErrorMessage.css';

const ErrorMessage = ({ title, description, recoverLabel, recoverHref }) => {
  const history = useHistory();

  const handleRecover = () => {
    history.push(recoverHref);
  };

  const showRecoverButton = !!recoverHref;

  return (
    <PageContainer>
      <h1>{title}</h1>

      { description && <p>{description}</p> }

      {showRecoverButton && (
        <p className="ErrorMessage-action">
          <Button large onClick={handleRecover} content={recoverLabel || 'Try again'} />
        </p>
      )}
    </PageContainer>
  );
};

export default ErrorMessage;
