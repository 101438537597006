import React from 'react';
import './GridCell.css';

const GridCell = ({ children }) => {
  return (
    <div className="GridCell">
      {children}
    </div>
  );
};

export default GridCell;
