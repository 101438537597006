import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import './TasterBadge.css';

let panel;

const TasterBadge = ({ pilotId }) => {
  const location = useLocation();

  const visible = !location.pathname.includes('listen');

  useEffect(() => {
    if (!panel) {
      console.log('requiring pilot lib');
      window.require(['pilot-lib/taster-offsite-panel'], (Panel) => {
        panel = new Panel('.taster-offsite-panel');
      });
    }
  }, []);

  return (
    <div className="TasterBadge">
      <iframe
        title="Taster badge"
        className={classnames(
          'taster-offsite-panel',
          'wide',
          {
            visible,
          },
        )}
        src={`//www.bbc.co.uk/taster/projects/${pilotId}/offsite/wide`}
        frameBorder="0"
        scrolling="no"
      />
    </div>
  );
};

export default TasterBadge;
