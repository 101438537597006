import React from 'react';
import './TextInput.css';

const TextInput = ({
  disabled,
  onChange,
  placeholder,
  readOnly,
  type = "text",
  value,
}) => (
  <input 
    className="TextInput"
    disabled={disabled}
    onChange={onChange}
    placeholder={placeholder}
    readOnly={readOnly}
    type={type}
    value={value}
  />
);

export default TextInput;
