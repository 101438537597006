import React from 'react';
import { randomColorHSV } from './random';

var mp3_prefix = "https://sound-effects-media.bbcrewind.co.uk/mp3/"

class Sound {
  constructor(index, id, {
    position = {},
    volume = 1.0,
    start = 0,
    end,
    delay = 0,
    loop = true,
  }) {
    this.index = index;
    this.id = id;
    this.src = mp3_prefix + id + ".mp3";
    this.position = position;
    this.volume = Math.min(1.0, Math.max(0, volume));
    this.start = start;
    this.end = end;
    this.loop = loop;
    this.delay = delay || 0; // because it could be set to 'null'?!
  }

  getAudioElement() {
    return <audio key={this.index} crossOrigin="anonymous" id={`audio_${this.index}`} src={this.src} preload="auto"></audio>;
  }

  getJSONformat() {
    return {
      "id": this.id,
      "mixerSettings": {
        "isLooping": this.loop,
        "volume": this.volume,
        "start": this.start,
        "end": this.end,
        "delay": this.delay,
        // TODO round coordinates to 2 decimal places (cm) to keep mix string shorter?
        "position": {
          "x": this.position.x,
          "y": this.position.y,
          "z": this.position.z
        }
      }
    }
  }

  getResonanceElement() {
    const {
      index,
      id,
      position,
      loop,
      volume,
      delay,
      start,
      end,
    } = this;
    const { x, y, z } = position;
    const audioEl = `#audio_${index}`;
    const color = randomColorHSV(50, 50, 50, 10, 10, 10);

    return (
      <a-entity
        key={index}
        position={[x, y, z].join(' ')}
        handle-events={`id: ${id}; index: ${index}`}
        playback-control={`audioEl: ${audioEl}; delay: ${delay}; loop: ${loop}; start: ${start}; end: ${end}`}
        envelope-visualisation={`audioEl: ${audioEl}; id: ${id}`}
      >
        <a-resonance-audio-src
          visualize={false}
          enableanalyser={false}
          id={`${id}_src`}
          src={audioEl}
          gain={volume}
          loop={loop}
          autoplay={false}
          room="#room"
        />
        <a-sphere
          scale="0.2 0.2 0.2"
          material={`shader: standard; color: ${color}; transparent: true; opacity: 0.5`}
          class="clickable"
        />
        <a-box position="0 -0.2 0" scale="0.1 0.1 0.1" />
      </a-entity>
    );
  }
}

export default Sound;
