import Sound from './Sound';

// add random positions to all sounds that don't have them in the mix object.
// optionally ignore and replace any existing positions by setting force = true.
const addRandomPositions = (mixObject, {
  force = false,
} = {}) => {
  const mixerAssets = mixObject.simplifiedMixerAssets;

  const newMixerAssets = {};

  for (const key in mixerAssets) {
    const asset = mixerAssets[key];
    const { mixerSettings } = asset;

    if (mixerSettings.position && !force) {
      newMixerAssets[key] = asset;
    } else {
      newMixerAssets[key] = {
        ...asset,
        mixerSettings: {
          ...mixerSettings,
          position: {
            x: (Math.random() * 8) - 4,
            y: (Math.random() * 3) + 0.5,
            z: (Math.random() * 8) - 4,
          },
        },
      };
    }
  }

  return {
    simplifiedMixerAssets: newMixerAssets,
  };
};

const simplifyNumbers = (mixObject) => {
  const { simplifiedMixerAssets } = mixObject;

  const newMixerAssets = {};

  const simplify = (value) => {
    let num = Number(value);
    if (value === null || value === undefined || Number.isNaN(num)) {
      return value;
    }

    return parseFloat(num.toFixed(2));
  }

  [...Object.entries(simplifiedMixerAssets)].forEach(([index, { id, mixerSettings }]) => {
    newMixerAssets[index] = {
      id,
      mixerSettings: {
        ...mixerSettings,
        volume: simplify(mixerSettings.volume),
        start: simplify(mixerSettings.start),
        end: simplify(mixerSettings.end),
        delay: simplify(mixerSettings.delay),
        position: mixerSettings.position ? {
          x: simplify(mixerSettings.position.x),
          y: simplify(mixerSettings.position.y),
          z: simplify(mixerSettings.position.z),
        } : undefined,
      },
    };
  });

  return {
    ...mixObject,
    simplifiedMixerAssets: newMixerAssets,
  };
}

// convert between a JS object (mixObject) and base64 encoded JSON (mixString)
export const encodeMixString = (mixObject) => btoa(JSON.stringify(simplifyNumbers(mixObject)));
export const decodeMixString = (mixString) => {
  const mixObject = JSON.parse(atob(mixString));
  // TODO decide if we want to make the randomisation optional
  return addRandomPositions(mixObject);
};

// convert between a JS object (mixObject) and a URL with a 'mixstring' search parameter
export const decodeMixUrl = (url, paramName = 'mixstring') => {
  const mixString = new URL(url).searchParams.get(paramName);
  return decodeMixString(mixString);
};
export const encodeMixUrl = (mixObject, baseUrl = `${window.location.origin}/link`, paramName = 'mixstring') => {
  const url = new URL(baseUrl);
  url.searchParams.append(paramName, encodeMixString(mixObject));
  return url.toString();
};

// create a mix object from an array of Sound instances, only keeping the relevant properties
export const createMixObject = (sounds) => {
  const mixObject = {
    simplifiedMixerAssets: {},
  };

  for (const [index, sound] of sounds.entries()) {
    mixObject.simplifiedMixerAssets[`${index + 1}`] = sound.getJSONformat();
  }

  return mixObject;
};

// create a shareable URL from an array of Sound instances by first converting it to a mix object
export const createShareUrl = (sounds) => encodeMixUrl(createMixObject(sounds));

// create an array of Sound instances from a mixObject
export const createSounds = (mixObject) => {
  const mixerAssets = mixObject.simplifiedMixerAssets;
  const allSounds = [];

  for (const index in mixerAssets) {
    const asset = mixerAssets[index];

    const {
      volume,
      position,
      start,
      end,
      isLooping: loop,
      delay,
    } = asset.mixerSettings;

    allSounds.push(new Sound(index, asset.id, {
      position,
      volume,
      start,
      end,
      loop,
      delay,
    }));
  }
  return allSounds;
};

export const fetchMixMetadata = async (mixObject) => {
  const ids = [...Object.values(mixObject.simplifiedMixerAssets)].map(({ id }) => id);
  const query = ids.join(' ');

  const response = await fetch('https://sound-effects-api.bbcrewind.co.uk/api/sfx/search', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      criteria: {
        query,
        from: 0,
        size: ids.length,
      },
    }),
  });

  if (!response.ok) {
    return {};
  }

  const data = await response.json();

  const metadata = {};

  data.results.forEach(({ id, description, duration, recordedDate }) => {
    metadata[id] = {
      description,
      duration: duration / 1000, // convert milliseconds to seconds
      recordedDate,
    };
  });

  return metadata;
};
