import React from 'react';
import './SelectedSoundOverlay.css';
import { useSoundMetadata, useSettings } from '../App';

const formatVolume = (volume) => {
  return `${Math.round(volume * 100)}%`;
}

const formatPosition = ({ x, y, z }) => {
  return [x, y, z].map(d => d.toFixed(1)).join(' ');
}

const formatSettings = (mixerSettings) => {
  const { volume, position } = mixerSettings;

  return `volume: ${formatVolume(volume)}, position: ${formatPosition(position)}`;
};

const SelectedSoundOverlay = ({
  editedMix,
}) => {
  const {
    selectedIndex: index,
    mixObject: editedMixObject,
    currentInteraction,
  } = editedMix;
  // TODO error handling if any of these are undefined, but may not be needed if we have a simpler mixObject structure.
  const { id, mixerSettings } = editedMixObject.simplifiedMixerAssets[index];
  const { volume } = mixerSettings;

  const soundMetadata = useSoundMetadata();
  const { showDebugInfo } = useSettings();
  const metadata = soundMetadata[id];

  let hintText = 'Press to move, or pinch to change volume';

  if (currentInteraction === 'position') {
    hintText = 'Move to reposition the sound, then tap again to let go';
  } else if (currentInteraction === 'volume') {
    hintText = `Pinch to set volume`;
  }

  return (
    <div className="SelectedSoundOverlay">
      <div className="SelectedSoundOverlay-info">
        <h1>{id}</h1>
        {metadata && <p>{metadata.description}</p>}
        {showDebugInfo && <p>Debug info:<br /><code>{formatSettings(mixerSettings)}</code></p>}
      </div>
      <div className="SelectedSoundOverlay-toast">{hintText}</div>
    </div>
  );
};

export default SelectedSoundOverlay;
