import React from 'react';
import Icon from './Icon';
import './Thumbnail.css';

const Thumbnail = ({
  title,
  description,
  imageUrl,
  imageAlt,
  icon,
}) => {
  return (
    <div className="Thumbnail">
      <div className="Thumbnail-image">
        <img alt={imageAlt || title} src={imageUrl} />
        {icon && <div className="Thumbnail-icon"><Icon name={icon} /></div>}
      </div>
      <h1 className="Thumbnail-title">{title}</h1>
      {description && <p className="Thumbnail-description">{description}</p>}
    </div>
  );
};

export default Thumbnail;
